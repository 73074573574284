// Dashboard.vue

<template>
	<section>
		<div v-show="loggedIn" class="container">
			<!-- <h1>Reports</h1>

  <p class="lead text-muted">With each call, generate a new Patient Assessment Form that will be faxed to the Hospital.</p> -->

			<!--  <router-link tag="a" class="btn btn-sm btn-primary mb-3"  exact to="/assessment/new"><i class="material-icons">reorder</i>Create a Patient Assessment Form</router-link>


      <router-link tag="a" class="btn btn-primary mb-3"  exact to="/assessment/new"><i class="material-icons">reorder</i>Create a Patient Assessment Form</router-link>
   -->

			<!--     <router-link tag="a" class="btn btn-lg btn-primary"  exact to="/assessment/new">
        <i class="material-icons">&#xE548;</i>Create a Patient Assessment Form</router-link> -->

			<router-link
				v-if="userCanSee.assessment"
				tag="a"
				class="btn btn-lg btn-primary"
				style="z-index: 1000; position: relative;"
				exact
				to="/assessment/new/init"
			>
				<i class="material-icons">note_add</i>Create a Patient Assessment
				Form</router-link
			>

			<!--
        <a class="waves-effect waves-light btn-large">Button</a>
  <a class="waves-effect waves-light btn-large"><i class="material-icons left">cloud</i>button</a>
  <a class="waves-effect waves-light btn-large"><i class="material-icons right">cloud</i>button</a>
           -->
		</div>

		<app-reports-all v-if="loggedIn"></app-reports-all>
	</section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ReportsAll from "./reports/ReportsAll.vue";

export default {
	data() {
		return {
			msg: "Welcome to Your Vue.js App"
		};
	},

	methods: {
		...mapActions([
			"ajax"
			//'setDestination'
		])
	},

	components: {
		"app-reports-all": ReportsAll
	},

	computed: {
		...mapGetters([
			"userCanSee",
			"loggedIn",
			"ajaxLoader" // context.state.ajaxLoader = true;
		])
	},

	mounted() {}
};
</script>

<style lang="scss"></style>
