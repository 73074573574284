var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('table',{staticClass:"table table-bordered table-sm"},[_vm._m(0),_vm._l((_vm.facility_usage_reports.facilities),function(facility){return _c('tr',{key:facility.id + '-facility-usage-reports'},[_c('td',[_vm._v(_vm._s(facility.name))]),_c('td',[_c('div',[_vm._v(" "+_vm._s(facility.users_count)+" of "),(facility.user_limit < 0)?_c('span',[_vm._v("Unlimited")]):_c('span',[_vm._v(_vm._s(facility.user_limit))]),_vm._v(" Users ")]),(facility.user_limit > 0)?_c('div',{staticClass:"progress light-green lighten-1 mt-0 mb-2",staticStyle:{"height":"10px"}},[_c('div',{staticClass:"progress-bar light-green darken-2",staticStyle:{"width":"40%","height":"10px"},style:('width: ' +
								_vm.returnPercentage(facility.users_count, facility.user_limit))})]):_vm._e(),(facility.rtnow_feature.active)?_c('span',[_vm._v(" "+_vm._s(facility.rtnow_feature.total_minutes_used)+" of "+_vm._s(facility.rtnow_feature.contracted_minutes > 0 ? facility.rtnow_feature.contracted_minutes : "Unlimited")+"  rtNOW minutes "),(facility.rtnow_feature.contracted_minutes > 0)?_c('div',{staticClass:"progress light-green lighten-1 mt-0 mb-2",staticStyle:{"height":"10px"}},[_c('div',{staticClass:"progress-bar light-green darken-2",staticStyle:{"height":"10px"},style:('width: ' +
									_vm.returnPercentage(
										facility.rtnow_feature.total_minutes_used,
										facility.rtnow_feature.contracted_minutes
									))})]):_vm._e()]):_vm._e(),(facility.corelink_feature.active)?_c('div',[_vm._v(" "+_vm._s(facility.corelink_feature.total_minutes_used)+" of "+_vm._s(facility.corelink_feature.contracted_minutes > 0 ? facility.corelink_feature.contracted_minutes : "Unlimited ")+" CoreLink minutes "),(facility.corelink_feature.contracted_minutes > 0)?_c('div',{staticClass:"progress light-green lighten-1 mt-0 mb-2",staticStyle:{"height":"10px"}},[_c('div',{staticClass:"progress-bar light-green darken-2",staticStyle:{"height":"10px"},style:('width: ' +
									_vm.returnPercentage(
										facility.corelink_feature.total_minutes_used,
										facility.corelink_feature.contracted_minutes
									))})]):_vm._e()]):_vm._e()])])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{attrs:{"width":"30%"}},[_vm._v("Facility")]),_c('th',[_vm._v("Usage")])])
}]

export { render, staticRenderFns }