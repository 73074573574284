<template>
	<!-- RTS -->
	<div id="callLogs" class="row p-2 my-5" v-show="call_logs.length">
		<div class="col-sm-8 col-md-4 p-2 dashboard-widget">
			<div class="yellow darken-3 text-white">
				<div class="p-3">
					<span class="total_name">Call Logs</span>
					<h2 class="total_big">
						{{ call_logs.length }}
						<!-- <small class="cj qv">5%</small> -->
					</h2>
				</div>
				<i class="material-icons">headset_mic</i>
			</div>
		</div>

		<div class="col-md-8 pt-2">
			<table class="table table-bordered table-sm">
				<tr>
					<th>Id</th>
					<th style="text-align: right;" title="Answered Calls">
						Answered
						<i class="material-icons">info</i>
					</th>
					<th style="text-align: right;" title="Hours Scheduled">
						Start
						<i class="material-icons">info</i>
					</th>
					<th style="text-align: right;" title="Missed Calls">
						End
						<i class="material-icons">info</i>
					</th>
					<th style="text-align: right;" title="Missed Calls">
						RT
						<i class="material-icons">info</i>
					</th>
					<th style="text-align: right;">&nbsp;</th>
				</tr>
				<tr
					v-for="(call, index) in call_logs"
					:key="call.id"
					:class="{ 'table-warning': index == callLogIndex }"
				>
					<td style="text-align: right;">{{ call.id }}</td>
					<td style="text-align: right;">b</td>
					<td style="text-align: right;">{{ call.start_time }}</td>
					<td style="text-align: right;">{{ call.end_time }}</td>
					<td style="text-align: right;">{{ call.rt }}</td>

					<td style="text-align: right;">
						<a
							@click.prevent="viewCallLog(index)"
							href="#"
							class="btn btn-sm yellow darken-3 text-light"
							:class="{ 'darken-4': index == callLogIndex }"
							>View</a
						>
					</td>
				</tr>
			</table>
		</div>

		<app-modal :modal="viewCallLogModal">
			<span slot="title">Call Log</span>

			<form>
				<pre style="display: block !important; opacity: 1; max-height: auto;">
activeCallLog: {{ activeCallLog }}</pre
				>
			</form>

			<template slot="footer">
				<!-- <button
					type="button"
					class="btn btn-secondary"
					data-dismiss="modal"
					v-on:click.prevent="viewCallLogModal.show = false"
				>Cancel</button>-->
				<button
					:disabled="callLogIndex == 0"
					type="button"
					class="btn blue-grey text-light"
					data-dismiss="modal"
					v-on:click.prevent="callLogIndex--"
				>
					Previous
				</button>

				<ul class="pagination">
					<li
						v-for="(p, i) in call_logs"
						:key="i + '-call-logs'"
						class="page-item"
						v-show="i > callLogIndex - 4 && i < callLogIndex + 4"
					>
						<a
							@click.prevent="viewCallLog(i)"
							class="page-link grey-text"
							:class="{ 'blue-grey text-light white-text': callLogIndex == i }"
							href="#"
							>{{ i + 1 }}</a
						>
					</li>
				</ul>

				<button
					v-if="callLogIndex < call_logs.length - 1"
					type="button"
					class="btn yellow darken-3 text-light"
					data-dismiss="modal"
					v-on:click.prevent="callLogIndex++"
				>
					Next
				</button>
				<!-- <button type="button" class="btn btn-danger" v-on:click.prevent="archiveUser(true)">Archive</button> -->
			</template>
		</app-modal>
	</div>
</template>

<script>
//import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
//import Comp from "@/components/Comp.vue";

export default {
	name: "CallLogs.vue",
	props: ["call_logs"],
	data: function() {
		return {
			//errors: []
			callLogIndex: null,
			viewCallLogModal: {
				show: false
			}
		};
	},
	components: {
		//Comp
	},
	computed: {
		//...mapState(["spin"]),
		activeCallLog() {
			return this.call_logs[this.callLogIndex];
		}
	},
	watch: {
		//people: {
		//  handler: function (newVal, oldVal) {},
		//  deep: true,
		//  immediate: true
		//}
	},
	methods: {
		//...mapActions(["ajax"]),
		//...mapMutations(["ajax"]),
		viewCallLog(index) {
			this.callLogIndex = index;
			this.viewCallLogModal.show = true;
		}
	},
	mounted() {}
};
</script>

<style lang="scss">
#callLogs {
	.modal-dialog {
		max-width: 850px;
	}
	.modal-footer {
		justify-content: space-between;
	}
	.pagination {
		margin: 0;
		width: auto;
	}
}
</style>
