// reports/CallsToFacilities.vue

<template>
	<div>
		<table class="table table-bordered table-sm">
			<tr>
				<th>Facility</th>
				<th style="text-align: right;">Calls</th>
			</tr>
			<tr
				v-for="(facility, i) in callsToFacilities"
				:key="i + '-calls-to-facilities'"
			>
				<td>{{ facility.name }}</td>
				<td style="text-align: right;">{{ facility.call_count }}</td>
			</tr>
		</table>
	</div>
</template>

<script>
export default {
	props: ["callsToFacilities"],
	name: "CallsToFacilitiesTable.vue",
	data() {
		return {
			msg: "Welcome to Your Vue.js App"
		};
	}, // data

	mounted() {} // mounted
};
</script>

<style lang="scss"></style>
