// Welcome.vue

<template>
	<div class="" style="margin-top: -20px;">
		<canvas :id="canvasId" width="400" height="100"></canvas>
	</div>
</template>

<script>
export default {
	props: ["canvasId"],
	name: "BarChart.vue",

	data() {
		return {
			msg: "Welcome to Your Vue.js App"
		};
	},

	mounted() {
		//myChart
		let ctx = document.getElementById(this.canvasId);

		//WidgetChart 4
		//var ctx = document.getElementById( "widgetChart4" );
		//ctx.height = 70;

		// eslint-disable-next-line no-unused-vars,no-undef
		var myChart = new Chart(ctx, {
			type: "bar",
			data: {
				labels: [
					"a",
					"b",
					"c",
					"d",
					"e",
					"f",
					"g",
					"h",
					"i",
					"j",
					"k",
					"l",
					"m",
					"n",
					"o",
					"p"
				],
				datasets: [
					{
						label: "",
						data: [
							78,
							81,
							80,
							45,
							34,
							12,
							40,
							75,
							34,
							89,
							32,
							68,
							54,
							72,
							18,
							98
						],
						borderColor: "rgba(0, 123, 255, 0.9)",
						//borderWidth: "0",
						backgroundColor: "rgba(255,255,255,.3)"
					}
				]
			},
			options: {
				maintainAspectRatio: true,
				legend: {
					display: false
				},
				scales: {
					xAxes: [
						{
							display: false,
							categoryPercentage: 1,
							barPercentage: 0.5
						}
					],
					yAxes: [
						{
							display: false
						}
					]
				}
			}
		});
	}
};
</script>

<style lang="scss"></style>
