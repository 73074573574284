// Welcome.vue

<template>
	<div class="col-12">
		<br />
		<br />
		<div class="whm-15">
			<div class="whm-col" style="width:10%">
				<div class="whm-row whm-day-name">&nbsp;-</div>
				<div class="whm-row">
					<div class="whm-hour">12:00 am</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">1:00 am</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">2:00 am</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">3:00 am</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">4:00 am</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">5:00 am</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">6:00 am</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">7:00 am</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">8:00 am</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">9:00 am</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">10:00 am</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">11:00 am</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">12:00 pm</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">1:00 pm</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">2:00 pm</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">3:00 pm</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">4:00 pm</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">5:00 pm</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">6:00 pm</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">7:00 pm</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">8:00 pm</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">9:00 pm</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">10:00 pm</div>
				</div>
				<div class="whm-row">
					<div class="whm-hour">11:00 pm</div>
				</div>
			</div>

			<div
				class="whm-col"
				v-for="(day, i) in gaps_array"
				:style="colWidth"
				:key="i + '-gaps-array'"
			>
				<div class="whm-row whm-day-name">{{ day.name }}&nbsp;</div>
				<div
					class="whm-row"
					v-for="(hour, h) in day.hours"
					:key="h + '-day-hour'"
				>
					<a
						v-for="(fifteen, n) in hour.fifteen"
						:key="n + '-hour-fifteen'"
						class="whm-color blue-grey"
						:style="{ opacity: 1 }"
						:class="{
							'red has_infotip': !isMet(
								fifteen.met.states,
								fifteen.met.facilities,
								day.name
							)
						}"
					>
						&nbsp;
						<div class="infotip">
							{{ day.name }} {{ day.date }}
							<br />
							{{ hour.name }}:{{ fifteen.name }}
							<br />
							<span v-if="!isMet(fifteen.met.states, false, '')">
								<br />States:
								<ul>
									<li
										v-for="(st, index) in fifteen.met.states"
										v-show="!st.is_met"
										:key="index + '-fifteen-met-states'"
									>
										{{ st.name }}
									</li>
								</ul>
							</span>
							<span v-if="!isMet(false, fifteen.met.facilities, '')">
								<br />Facilities:
								<ul>
									<li
										v-for="(fa, z) in fifteen.met.facilities"
										:key="z + '-fifteen-met-facilities'"
										v-show="!fa.is_met"
									>
										{{ fa.name }}
									</li>
								</ul>
							</span>
						</div>
					</a>
				</div>
			</div>
			<br />
			<br />
		</div>
	</div>
</template>

<script>
import moment from "moment";

import { mapActions } from "vuex";

export default {
	props: ["filterFuture"],
	name: "VacantShiftsAdminTable.vue",
	data() {
		return {
			msg: "Welcome to Your Vue.js App",
			gaps_array: [{}],
			myDate: moment().format("YYYY-MM-DD"),
			dayIterator: 0,
			facility_days_ahead: 7
		};
	}, // data

	methods: {
		...mapActions(["ajax"]),

		// eslint-disable-next-line no-unused-vars
		isMet(states, facilities, day_name) {
			//console.log("isMet myDate:::::::::: : " + this.myDate);
			//console.log(day_name + " - - - - - - - - - - - - - - - - - - -");
			//console.log("isMet states", states);
			//	console.log("isMet facilities", facilities);

			if (facilities.length < 1 || states.length < 1) {
				return false;
			}

			if (facilities) {
				var findMissingFacilities = facilities.find(element => {
					return element.is_met == false;
				});

				if (findMissingFacilities) {
					return false;
				}
			}

			if (states) {
				var findMissingStates = states.find(element => {
					return element.is_met == false;
				});

				if (findMissingStates) {
					return false;
				}
			}

			return true;
		}, // isMet()

		goToSchedule() {
			this.$router.push({ name: "schedule" });
		},
		// formatDate(date) {
		// 	return moment(date).format("MMM DD, Y");
		// },
		ajaxVacantShiftsAdminGap: function() {
			this.ajax({
				url: "/report/vacant_shifts_admin_gap",
				data: { date: this.myDate },
				success: json => {
					this.gaps_array.push({
						name: moment(this.myDate).format("ddd"),
						hours: json.gaps,
						date: this.myDate
					});
					if (this.dayIterator < this.filterFuture.days_ahead - 1) {
						//alert("report/vacant_shifts_admin_gap " + this.dayIterator);
						this.dayIterator++;
						// this.gaps_array.push({
						// 	name: moment(this.myDate).format("ddd"),
						// 	hours: json.gaps,
						// 	date: this.myDate
						// });

						var new_date = moment(this.myDate).add(1, "days");
						//new_date.add(1, "days");

						this.myDate = new_date.format("YYYY-MM-DD");

						this.ajaxVacantShiftsAdminGap();
					} else {
						this.dayIterator = 0;
					}
				}
			}); // ajax
		} // ajaxVacantShiftsAdminGap()
	}, // methods

	watch: {
		// deep watch
		// filterFuture: {
		// 	handler: () => {
		// 		console.log("filterFuture");
		// 		setTimeout(() => {
		// 			this.ajaxVacantShiftsAdminGap();
		// 		}, 500);
		// 	},
		// 	deep: true
		// },

		"filterFuture.days_ahead": function() {
			console.log("filterFuture");
			this.myDate = moment().format("YYYY-MM-DD");
			this.gaps_array = [];
			this.ajaxVacantShiftsAdminGap();
		}
	},

	computed: {
		colWidth() {
			var f = 90 / this.gaps_array.length;
			return "width: " + f + "%;";
		}
	},

	mounted() {} // mounted
};
</script>

<style lang="scss">
.whm-15 {
	width: 100%;
	.whm-col {
		.whm-row {
			//background: #dee2e6;
			overflow: inherit;
			height: 62px;
			.whm-color {
				line-height: 14px;
				border-bottom: 1px solid white;
				display: block;
			}

			.red {
				background-color: #f44336 !important;
			}
		}
		.whm-day-name {
			height: auto;
			background: transparent;
			overflow-x: hidden;
		}
	}
}

.infotip {
	display: none;
}

.has_infotip {
	position: relative;

	.infotip {
		position: absolute;
		left: 50%;
		transform: translate(-50%, -10px);
		bottom: 100%;
		background-color: black;
		color: white;
		display: none;
		padding: 10px;
		max-width: 200px;
		font-size: 14px;

		line-height: 1.4em;

		&:after {
			content: "";
			position: absolute;
			display: block;
			left: 50%;
			margin-left: -10px;
			top: 100%;
			width: 0;
			height: 0;
			border: 10px solid transparent;
			border-top: 10px solid black;
		}

		ul {
			margin: 0 0 0 15px;
			list-style-type: disc;
		}
	}
}

.has_infotip:hover {
	z-index: 99999;
	.infotip {
		display: block;
		text-align: left;
	}
}
</style>
