// Welcome.vue

<template>
	<div class="" style="margin-top: -20px;">
		<canvas :id="canvasId" width="400" height="100"></canvas>
	</div>
</template>

<script>
import moment from "moment";

export default {
	props: ["canvasId", "callData", "startDate", "endDate"],
	name: "CallLofLineChart.vue",

	data() {
		return {
			msg: "Welcome to Your Vue.js App",
			myChart: null
		};
	},

	watch: {
		callData: function() {
			//alert('watch dow_count');

			this.initChartData();
		}
	},

	methods: {
		// Returns an array of dates between the two dates
		enumerateDaysBetweenDates(startDate, endDate) {
			startDate = moment(startDate);
			endDate = moment(endDate);

			var now = startDate,
				dates = [];

			//alert(now);

			while (now.isBefore(endDate) || now.isSame(endDate)) {
				dates.push(now.format("YYYY-MM-DD"));
				now.add(1, "days");
			}
			//alert(dates.length);
			return dates;
		},

		initChartData() {
			// this.callData.dow_counts.forEach((row)=>{
			//
			// });

			var labels = [];
			var data = [];

			var dates = this.enumerateDaysBetweenDates(this.startDate, this.endDate);

			dates.forEach(row => {
				var date = moment(row).format("MMM DD, Y");
				labels.push(date);

				var hasDate = this.callData.call_volume.filter(ele => {
					//moment(row).format('MMM DD, Y')
					return date == moment(ele.start).format("MMM DD, Y");
				});

				data.push(hasDate.length);
			});

			//for (let [key,value] of Object.entries(this.callData.call_volume)) {

			/*
      for (let [key,value] of Object.entries(this.callData.call_volume)) {


        labels.push(value.start_date);
        data.push(value.id);

      }
      */

			//myChart
			var ctx = document.getElementById(this.canvasId);

			if (this.myChart != null) {
				this.myChart.destroy();
			}
			//ctx.height = 150;
			// eslint-disable-next-line no-undef
			this.myChart = new Chart(ctx, {
				type: "line",
				data: {
					labels: labels, // an array //['January', 'February', 'March', 'April', 'May', 'June', 'July'],
					type: "line",
					datasets: [
						{
							data: data, //[1, 18, 9, 17, 34, 22, 11],
							label: "calls: ",
							backgroundColor: "rgba(255,255,255,0.1)",
							borderColor: "rgba(255,255,255,.55)"
						}
					]
				},
				options: {
					maintainAspectRatio: true,
					legend: {
						display: false
					},
					responsive: true,
					tooltips: {
						mode: "index",
						titleFontSize: 12,
						titleFontColor: "#000",
						bodyFontColor: "#000",
						backgroundColor: "#fff",
						titleFontFamily: "Arial",
						bodyFontFamily: "Arial",
						cornerRadius: 3,
						intersect: false
					},
					scales: {
						xAxes: [
							{
								gridLines: {
									color: "transparent",
									zeroLineColor: "transparent"
								},
								ticks: {
									fontSize: 2,
									fontColor: "transparent"
								}
							}
						],
						yAxes: [
							{
								display: false,
								ticks: {
									display: false
								}
							}
						]
					},
					title: {
						display: false
					},
					elements: {
						line: {
							tension: 0.00001,
							borderWidth: 4
						},
						point: {
							radius: 0,
							hitRadius: 10,
							hoverRadius: 10
						}
					}
				}
			});
		} //initChartData
	}, // methods

	mounted() {
		//this.initChartData();
	}
};
</script>

<style lang="scss"></style>
