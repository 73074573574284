// /reports/ReportsAll.vue

<template>
	<!--

  Call volume based on hours of the day. (hourly heat chart)

  Bullets:

    - Average length of call

    - Total Calls

    - Total Busy Screens  (missed calls?)

  Vacant Shifts Reports

  Numbers of calls per facilities - filter

  Count of busy screens:



    - Hourly breakdown of missed calls (hourly heat chart)

  Busy/Missed Calls (table)

    - Agent/s scheduled at time of missed calls

    - Facilty affected ()

  rtNOW RTs (Agents) Breakdown:

    - Total Hours Scheduled

    - Calls Answered

    - Count of Missed Calls (Busy Screens) During Their Shift

    - Average Call Legth

	-->
	<!-- <pre>filter: {{filter}}</pre> -->
	<div class="container mt-3">
		<div class="row p-2" v-if="filter.start_date">
			<div class="col-sm-4 p-2">
				<label>From</label>
				<datepicker
					v-validate="'required'"
					:disabled="disabledDates"
					name="start"
					v-model="filter.start_date"
					:format="format"
					input-class="form-control"
				></datepicker>
			</div>
			<div class="col-sm-4 p-2">
				<label>To</label>
				<datepicker
					v-validate="'required'"
					:disabled="disabledDates"
					name="start"
					v-model="filter.end_date"
					:format="'MM/dd/yyyy'"
					input-class="form-control"
				></datepicker>
			</div>
		</div>

		<!-- CALL LOGS -->
		<call-logs v-if="call_logs.length" :call_logs="call_logs"></call-logs>

		<!-- TOTAL CALLS -->
		<div class="row p-2 my-5" v-if="iAmAdmin">
			<div class="col-sm-8 col-md-4 p-2">
				<div class="orange darken-2 text-white">
					<div class="p-3 pb-0">
						<span class="total_name">Total Calls</span>
						<h2 class="total_big">
							{{ callData.call_volume.length }}
							<!-- <small class="cj qv">5%</small> -->
							<i class="material-icons">phone_in_talk</i>
						</h2>
					</div>

					<app-call-log-line-chart
						:startDate="computedFilter.start_date"
						:endDate="computedFilter.end_date"
						:canvasId="'myChart'"
						:callData="callData"
					></app-call-log-line-chart>
				</div>
			</div>

			<div class="col-md-8 pt-2">
				<app-weekly-heat-map
					:callData="callData"
					:colorClass="'orange darken-3'"
				></app-weekly-heat-map>
			</div>
		</div>

		<!-- MISSED CALLS -->
		<div class="row p-2 my-5" v-if="iAmAdmin">
			<div class="col-sm-8 col-md-4 p-2">
				<div class="red darken-1 text-white">
					<div class="p-3">
						<span class="total_name">Missed Calls</span>
						<h2 class="total_big">
							{{ missedCallData.call_volume.length }}
							<i class="material-icons">phone_missed</i>

							<small style="font-size: .5em; font-weight: 100" class="cj qv"
								>{{ percentageOfMissedCalls }}%</small
							>
						</h2>
					</div>
					<app-pie-chart
						:canvasId="'myChart2'"
						:missedCalls="missedCallData.call_volume.length"
						:totalCalls="callData.call_volume.length"
					></app-pie-chart>
				</div>
			</div>

			<div class="col-md-8 pt-2">
				<app-weekly-heat-map
					:callData="missedCallData"
					:colorClass="'red darken-2'"
				></app-weekly-heat-map>
			</div>
		</div>

		<!-- FACILTY USAGE REPORT -->
		<div class="row p-2 my-5" v-if="userHasRTFeature || userRTNowAdmin">
			<div class="col-sm-8 col-md-4 p-2 dashboard-widget pt-4">
				<div class="light-green darken-2 text-white">
					<div class="p-3">
						<span class="total_name">Facility Usage</span>
						<h2 class="total_big">{{ monthYear }}</h2>
					</div>
					<i class="material-icons">location_city</i>
				</div>

				<pre style="display: block">
facility_usage_reports: {{ facility_usage_reports }}</pre
				>
			</div>

			<div class="col-md-8 pt-4">
				<!--				<app-call-log-table :callData="callData"></app-call-log-table>-->
				<facility-usage-reports
					:facility_usage_reports="facility_usage_reports"
				></facility-usage-reports>
			</div>
		</div>

		<!-- AVERAGE CALL -->
		<div class="row p-2 my-5" v-if="userHasRTFeature || userRTNowAdmin">
			<div class="col-sm-8 col-md-4 p-2">
				<div class="purple darken-1 text-white mt-3">
					<div class="p-3">
						<span class="total_name">Average Length of Call</span>
						<h2 class="total_big">
							{{ callData.duration_avg }}
							<!-- <small class="cj qv">5%</small> -->
							<i class="material-icons">timer</i>
						</h2>
					</div>
					<app-bar-chart :canvasId="'myChart3'"></app-bar-chart>
				</div>
			</div>

			<div class="col-md-8 pt-4">
				<app-call-log-table :callData="callData"></app-call-log-table>
			</div>
		</div>

		<!-- VACANT ADMIN SHIFTS -->
		<div class="row p-2 my-5" v-if="userRTNowAdmin">
			<div class="col-sm-8 col-md-4 p-2 dashboard-widget">
				<div class="blue-grey darken-1 text-white">
					<div class="p-3">
						<span class="total_name">Vacant Admin Shifts</span>
						<h2 class="total_big">
							&nbsp;
							<!-- <small class="cj qv">5%</small> -->
						</h2>
					</div>
					<i class="material-icons">event_busy</i>
				</div>
			</div>

			<div class="pt-2 _row">
				<div
					class="col-md-8 btn-group mb-3"
					role="group"
					aria-label="Basic example"
				>
					<button
						v-on:click.prevent="filterFuture.days_ahead = 4"
						type="button"
						class="btn blue-grey darken-3 text-white opacity-70"
						:class="{ 'opacity-100': filterFuture.days_ahead == 4 }"
					>
						4 days
					</button>
					<button
						v-on:click.prevent="filterFuture.days_ahead = 7"
						type="button"
						class="btn blue-grey darken-3 text-white opacity-70"
						:class="{ 'opacity-100': filterFuture.days_ahead == 7 }"
					>
						7 days
					</button>
					<button
						v-on:click.prevent="filterFuture.days_ahead = 10"
						type="button"
						class="btn blue-grey darken-3 text-white opacity-70"
						:class="{ 'opacity-100': filterFuture.days_ahead == 10 }"
					>
						10 days
					</button>
				</div>
			</div>
			<vacant-shifts-admin-table
				:filterFuture="filterFuture"
			></vacant-shifts-admin-table>
		</div>

		<!-- VACANT FACILITIES SHIFTS -->
		<div class="row p-2 my-5 pt-4" v-if="userHasRTFeature || userRTNowAdmin">
			<div class="col-sm-8 col-md-4 p-2 dashboard-widget">
				<div class="purple lighten-1 text-white">
					<div class="p-3">
						<span class="total_name">Vacant Facility Shifts</span>
						<h2 class="total_big">
							&nbsp;
							<!-- <small class="cj qv">5%</small> -->
						</h2>
					</div>
					<i class="material-icons">event_busy</i>
				</div>
			</div>

			<div class="pt-2 _row">
				<div
					class="col-md-8 btn-group mb-3"
					role="group"
					aria-label="Basic example"
				>
					<button
						v-on:click.prevent="facility_days_ahead = 4"
						type="button"
						class="btn purple darken-1 text-white opacity-70"
						:class="{ 'opacity-100': facility_days_ahead == 4 }"
					>
						4 days
					</button>
					<button
						v-on:click.prevent="facility_days_ahead = 10"
						type="button"
						class="btn purple darken-1 text-white opacity-70"
						:class="{ 'opacity-100': facility_days_ahead == 10 }"
					>
						10 days
					</button>
					<button
						v-on:click.prevent="facility_days_ahead = 30"
						type="button"
						class="btn purple darken-1 text-white opacity-70"
						:class="{ 'opacity-100': facility_days_ahead == 30 }"
					>
						30 days
					</button>
					<button
						v-on:click.prevent="facility_days_ahead = 60"
						type="button"
						class="btn purple darken-1 text-white opacity-70"
						:class="{ 'opacity-100': facility_days_ahead == 60 }"
					>
						60 days
					</button>
				</div>
			</div>
			<vacant-shifts-facility-table
				:facility_days_ahead="facility_days_ahead"
			></vacant-shifts-facility-table>
		</div>

		<!-- FACILITIES -->
		<div class="row p-2 my-5" v-if="iAmAdmin">
			<div class="col-sm-8 col-md-4 p-2 dashboard-widget">
				<div class="light-green darken-2 text-white">
					<div class="p-3">
						<span class="total_name">Facilities</span>
						<h2 class="total_big">
							{{ callsToFacilities.length }}
							<!-- <small class="cj qv">5%</small> -->
						</h2>
					</div>
					<i class="material-icons">location_city</i>
				</div>
			</div>

			<div class="col-md-8 pt-2" v-if="callsToFacilities.length">
				<app-calls-to-facilities-table
					:callsToFacilities="callsToFacilities"
				></app-calls-to-facilities-table>
			</div>
		</div>

		<!-- RTS -->
		<div class="row p-2 my-5" v-if="userRTNowAdmin">
			<div class="col-sm-8 col-md-4 p-2 dashboard-widget">
				<div
					class="blue darken-2 text-white"
					style="background-image: url('/assets/rtnow-logo-greyscale.png'); background-position: center right; background-size: 50%; background-repeat: no-repeat;"
				>
					<div class="p-3">
						<span class="total_name"
							>Payroll Report{{ rtnow_agents.length > 1 ? "s" : "" }}</span
						>
						<h2 class="total_big">
							{{ rtnow_agents.length }}
							<!-- <small class="cj qv">5%</small> -->
						</h2>
					</div>
					<!-- <i class="material-icons ">person</i> -->
				</div>
			</div>

			<div class="col-md-8 pt-2">
				<table class="table table-bordered table-sm">
					<tr>
						<th>Name</th>
						<th style="text-align: right;" title="Answered Calls">
							Answered
							<i class="material-icons">info</i>
						</th>
						<th style="text-align: right;" title="Total Hours Scheduled">
							Total Hours Scheduled
							<i class="material-icons">info</i>
						</th>
<!--							Add weekend hours scheduled column with chris's request-->
						<th style="text-align: right;" title="Weekend Hours Scheduled">
							Weekend Hours Scheduled
							<i class="material-icons">info</i>
						</th>
<!--							Add normal hours scheduled column with chris's request-->
						<th style="text-align: right;" title="Normal Hours Scheduled">
							Normal Hours Scheduled
							<i class="material-icons">info</i>
						</th>
						<th style="text-align: right;" title="Missed Calls">
							Missed
							<i class="material-icons">info</i>
						</th>

<!--							delete average column with chris's request-->
<!--						<th style="text-align: right;" title="Average Call Length">-->
<!--							Average-->
<!--							<i class="material-icons">info</i>-->
<!--						</th>-->
					</tr>
					<tr v-for="(rt, i) in rtnow_agents" :key="i + '-rtnow-agents-ra'">
						<td>{{ rt.name }}</td>
						<td style="text-align: right;">{{ rt.answered }}</td>
<!--							total hours scheduled is same as scheduled hours renamed the column in the front end-->
						<td style="text-align: right;">{{ rt.scheduled }} hours</td>
<!--							will add weekend hours scheduled with chris's request-->
						<td style="text-align: right;">{{ rt.weekendHoursScheduled }} hours</td>
<!--							will add normal hours scheduled with chris's request-->
						<td style="text-align: right;">{{ rt.normalHoursScheduled }} hours</td>
						<td style="text-align: right;">
							{{ rt.missed }}
							<!-- <small> 12%</small> -->
						</td>
<!--							delete average column with chris's request-->
<!--						<td style="text-align: right;">{{ rt.average }}</td>-->
					</tr>
				</table>
			</div>
		</div>

		<!-- DOWNLOADS -->
		<div class="row p-2 my-5" v-if="selfUser && selfUser.rtnow_admin">
			<div class="col-sm-8 col-md-4 p-2 downloads-widget">
				<h2 class="mb-4">Download Reports</h2>
				<div class="d-flex">
					<a
						:href="
							'/report_docs/facility_call_report?start_date=' +
								url_start_date +
								'&end_date=' +
								url_end_date
						"
						target="_blank"
						class="btn btn-lg btn-warning"
						style="position: relative;"
					>
						<i class="material-icons">cloud_download</i>Facility Call Report
					</a>

					<a
						:href="
							'/report_docs/rtnow_facility_usage_report?start_date=' +
								url_start_date +
								'&end_date=' +
								url_end_date
						"
						target="_blank"
						class="btn btn-lg btn-success mx-2"
						style="position: relative;"
					>
						<i class="material-icons">cloud_download</i>Facility Video Usage
					</a>

					<a
						:href="
							'/report_docs/corelink_usage_report?start_date=' +
								url_start_date +
								'&end_date=' +
								url_end_date
						"
						target="_blank"
						class="btn btn-lg btn-info"
						style="position: relative;"
					>
						<i class="material-icons">cloud_download</i>CoreLink Video Usage
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// gaps: [
// 	{
// 		// 0
// 		name: "12",
// 		fifteen: [
// 			{
// 				requirements: {},
// 				met: {},
// 				name: "00"
// 			},
// 			{
// 				requirements: {},
// 				met: {},
// 				name: "15"
// 			},
// 			{
// 				requirements: {},
// 				met: {},
// 				name: "30"
// 			},
// 			{
// 				requirements: {},
// 				met: {},
// 				name: "45"
// 			}
// 		]
// 	}
// ];

import moment from "moment";

import Datepicker from "vuejs-datepicker";

import CallLogs from "./CallLogs.vue";
import CallLogTable from "./CallLogTable.vue";

import WeeklyHeatMap from "./WeeklyHeatMap.vue";
import VacantShiftsAdminTable from "./VacantShiftsAdminTable.vue";
import VacantShiftsFacilityTable from "./VacantShiftsFacilityTable.vue";
import CallsToFacilitiesTable from "./CallsToFacilitiesTable.vue";
import FacilityUsageReports from "./FacilityUsageReports";

import CallLogLineChart from "./CallLogLineChart.vue";
import BarChart from "./BarChart.vue";
import PieChart from "./PieChart.vue";

import { mapGetters, mapActions } from "vuex";

export default {
	//props: ['time'],
	name: "ReportsAll.vue",

	data() {
		return {
			monthYear: moment().format("MMMM YYYY"),
			facility_usage_reports: {
				facilities: null
			},
			call_logs: [],
			facility_days_ahead: null,

			msg: "Welcome to Your Vue.js App",

			onPage: true, // test for whether I am still on this page.

			format: "MM/dd/yyyy",
			filter: {
				start_date: false, //moment().subtract(30, 'days').format('MM/DD/YYYY'),
				end_date: moment().format("MM/DD/YYYY")
			},
			filterFuture: {
				days_ahead: 0
			},
			callData: {
				call_volume: [],
				duration_avg: "0:00:00"
			},
			missedCallData: {
				call_volume: []
			},
			rtnow_agents: [],
			//vacantShifts: [],
			//gaps: [],
			callsToFacilities: [],

			disabledDates: {
				//to: new Date(2016, 0, 5), // Disable all dates up to specific date
				from: new Date(
					moment().format("YYYY"),
					moment().format("M") - 1,
					moment().format("DD")
				) // Disable all dates after specific date
			}
		};
	},

	computed: {
		...mapGetters(["loggedIn", "selfUser"]),

		url_start_date() {
			return this.filter.start_date
				? encodeURIComponent(this.computedFilter.start_date)
				: "";
		},

		url_end_date() {
			return this.filter.end_date
				? encodeURIComponent(this.computedFilter.end_date)
				: "";
		},

		percentageOfMissedCalls() {
			var perc =
				(this.missedCallData.call_volume.length /
					this.callData.call_volume.length) *
				100;
			if (isNaN(perc)) {
				return "0";
			}
			return this.round(perc, 1);
		},

		computedFilter() {
			//var filter = this.filter;

			var filter = Object.assign({}, this.filter);

			filter.start_date = moment(this.filter.start_date).format("MM/DD/YYYY");
			filter.end_date = moment(this.filter.end_date).format("MM/DD/YYYY");

			return filter;
		},

		userRTNowAdmin() {
			return this.selfUser.rtnow_admin;
		},

		userHasRTFeature() {
			return !!this.selfUser.facilities.find(ele => {
				return ele.rtnow_feature === true;
			});
		},

		userIsFacilityAdmin() {
			return this.selfUser.facilities.find(ele => {
				return ele.admin === true;
			});
		},

		iAmAdmin() {
			//return (this.selfUser.rtnow_admin || this.selfUser.admin_clients.length || this.selfUser.admin_facilities.length)

			const userIsFacilityAdmin = this.selfUser.facilities.find(ele => {
				return ele.admin === true;
			});

			return (
				this.selfUser && (this.selfUser.rtnow_admin || userIsFacilityAdmin)
			);
			// return (
			// 	this.selfUser &&
			// 	(this.selfUser.rtnow_admin ||
			// 		(this.selfUser.admin_clients && this.selfUser.admin_clients.length) ||
			// 		(this.selfUser.admin_facilities &&
			// 			this.selfUser.admin_facilities.length))
			// );
		}
	},

	methods: {
		...mapActions(["ajax"]),

		round(value, precision) {
			var multiplier = Math.pow(10, precision || 0);
			return Math.round(value * multiplier) / multiplier;
		},

		ajaxFacilityUsageReports() {
			if (this.loggedIn && this.onPage) {
				//alert("1. ajaxCallVolume");
				this.ajax({
					url: "/report/facility_usage_reports",
					data: { filter: this.computedFilter },
					success: json => {
						this.facility_usage_reports = json;

						// 2.
						//this.ajaxMissedCallVolume();
					}
				}); // ajax
			}
		}, // ajaxFacilityUsageReports

		ajaxCallVolume() {
			if (this.loggedIn && this.onPage) {
				//alert("1. ajaxCallVolume");
				this.ajax({
					url: "/report/call_volume",
					data: { filter: this.computedFilter },
					success: json => {
						this.callData = json;

						// 2.
						this.ajaxMissedCallVolume();
					}
				}); // ajax
			}
		}, // ajaxCallVolume

		// .2
		ajaxMissedCallVolume() {
			if (this.loggedIn && this.onPage) {
				//alert("2. ajaxMissedCallVolume");
				this.ajax({
					url: "/report/missed_call_volume",
					data: { filter: this.computedFilter },
					success: json => {
						this.missedCallData = json;

						// .3
						//this.ajaxrtNOWRTdetail();
					}
				}); // ajax
			}
		}, // ajaxCallVolume

		//
		ajaxrtNOWRTdetail() {
			if (this.loggedIn && this.onPage) {
				this.ajax({
					url: "/report/rtNow_rt_detail",
					data: { filter: this.computedFilter },
					success: json => {
						this.rtnow_agents = json.rtnow_agents;
						//this.ajaxCallsToFacilities();
					}
				}); // ajax
			}
		}, //

		// .4
		ajaxCallsToFacilities() {
			if (this.loggedIn && this.onPage) {
				this.ajax({
					url: "/report/calls_to_facilities",
					data: { filter: this.computedFilter },
					success: json => {
						//this.callsToFacilities = json.facilities;

						this.$set(this, "callsToFacilities", json.facilities);

						//this.ajaxCallLogs();
						if (this.filterFuture.days_ahead == 0) {
							this.$set(this.filterFuture, "days_ahead", 4);
						}
					}
				}); // ajax
			}
		}, //

		// .5
		ajaxCallLogs() {
			if (this.loggedIn && this.onPage) {
				this.ajax({
					url: "/report/call_logs",
					data: { filter: this.computedFilter },
					success: json => {
						//this.callsToFacilities = json.facilities;

						this.$set(this, "call_logs", json.call_logs);

						// if (this.filterFuture.days_ahead == 0) {
						// 	this.$set(this.filterFuture, "days_ahead", 4);
						// }
					}
				}); // ajax
			}
		} //

		//
	},

	watch: {
		// deep watch
		filter: {
			handler: function() {
				if (this.filter.start_date) {
					if (this.userHasRTFeature || this.userRTNowAdmin) {
						// 1.
						//alert("user has rtnow_feature");
						this.ajaxCallVolume();
					}
					if (this.userIsFacilityAdmin || this.userRTNowAdmin) {
						//alert("user is an admin of some sort");
						this.ajaxFacilityUsageReports();
					}
					if (this.userRTNowAdmin) {
						//alert("user is an rtNOW admin only");
						this.ajaxrtNOWRTdetail();
					}
					//userHasRTFeature
					//userRTNowAdmin
					//userIsFacilityAdmin
				}
			},
			deep: true
		}
	}, // watch

	mounted() {
		this.facility_days_ahead = 4;

		this.onPage = true;

		setTimeout(() => {
			if (this.iAmAdmin) {
				this.filter.start_date = moment()
					.subtract(30, "days")
					.format("MM/DD/YYYY");
			} else {
				this.filter.start_date = moment()
					.subtract(1, "days")
					.format("MM/DD/YYYY");
			}

			this.ajaxCallsToFacilities();

			// 1.
			//this.ajaxCallVolume(); // starts a chain of api calls

			//this.ajaxVacantShifts();
		}, 1500);

		// setTimeout(()=> {
		//   if(this.iAmAdmin){
		//     //this.ajaxVacantShifts();
		//   }
		//
		// }, 3000);
	}, // mounted

	beforeRouteLeave(to, from, next) {
		this.onPage = false;

		next();
	},

	components: {
		FacilityUsageReports,
		"call-logs": CallLogs,
		"app-call-log-table": CallLogTable,

		"app-weekly-heat-map": WeeklyHeatMap,
		"vacant-shifts-admin-table": VacantShiftsAdminTable,
		"vacant-shifts-facility-table": VacantShiftsFacilityTable,
		"app-calls-to-facilities-table": CallsToFacilitiesTable,

		"app-call-log-line-chart": CallLogLineChart,
		"app-bar-chart": BarChart,
		"app-pie-chart": PieChart,
		Datepicker
	}
};
</script>

<style lang="scss">
.total_name {
	opacity: 0.7;
	text-transform: uppercase;
	font-weight: 300;
	letter-spacing: 0.07em;
}

.total_big {
	font-size: 3em;
}

.table-not-bordered {
	tr {
		border-bottom: none;
	}

	td,
	th {
		border-top: none;
		border-radius: 0;
		//border: 1px solid white;
		//border-top-color: transparent;
		//outline: 1px solid white;
		//margin: 1px;
	}
}

.dashboard-widget {
	& > div {
		position: relative;
	}

	.material-icons {
		position: absolute;
		right: 10px;
		top: 50%;
		font-size: 100px;
		margin-top: -50px;
		color: rgba(255, 255, 255, 0.25);
	}
}
</style>
