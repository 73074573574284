// Welcome.vue

<template>
	<div class="col-12" v-show="vacantShifts && vacantShifts.length">
		<br />

		<div
			v-for="(v, n) in vacantShifts"
			:key="n + '-vacant-shifts-facility-table'"
		>
			<div class="total_name mt-4 mb-3 purple-text darken-1">{{ v.name }}</div>

			<table class="table table-bordered table-sm">
				<tr>
					<th style="width: 20%">Date</th>
					<th>Location</th>
					<th style="width: 20%"></th>
				</tr>
				<tr
					v-for="(shift, ii) in v.vacant_shifts"
					:key="ii + '-v-vacant-shifts-name'"
				>
					<td v-text="formatDate(shift)"></td>
					<td>{{ v.name }}</td>

					<td align="right" style="text-align: right">
						<router-link
							class="btn btn-sm purple-text"
							active-class="active"
							exact
							:to="{ name: 'schedule' }"
						>
							Schedule
						</router-link>
					</td>
				</tr>
			</table>
		</div>
		<br />
	</div>
</template>

<script>
import moment from "moment";

import { mapActions, mapState } from "vuex";

export default {
	props: ["facility_days_ahead"],
	name: "VacantShiftsFaciltyTable.vue",
	data() {
		return {
			vacantShifts: null
		};
	}, // data

	methods: {
		...mapActions(["ajax"]),

		goToSchedule() {
			this.$router.push({ name: "schedule" });
		},
		formatDate(date) {
			return moment(date).format("MMM DD, Y");
		},

		ajaxVacantShiftsFacility: function() {
			//if (this.user.rtnow_admin) {
			this.ajax({
				url: "/report/vacant_shifts_facility",
				data: { days_ahead: this.facility_days_ahead },
				success: json => {
					this.vacantShifts = json.vacant_shifts;
				}
			}); // ajax
			//} else {
			//alert("User is not an RT admin.");
			//this.vacantShifts = [];
			//}
		} // ajaxVacantShiftsFacility()
	}, // methods

	watch: {
		facility_days_ahead: {
			handler: "ajaxVacantShiftsFacility"
			//immediate: true
		}

		//facility_days_ahead: "ajaxVacantShiftsFacility"
	},

	computed: {
		...mapState(["user"])
		// colWidth() {
		// 	var f = 90 / this.gaps_array.length;
		// 	return "width: " + f + "%;";
		// }
	},

	mounted() {
		//this.ajaxVacantShiftsFacility();
	} // mounted
};
</script>

<style lang="scss">
.whm-15 {
	width: 100%;
	.whm-col {
		.whm-row {
			//background: #dee2e6;
			overflow: inherit;
			height: 62px;
			.whm-color {
				line-height: 14px;
				border-bottom: 1px solid white;
				display: block;
				height: 25%;
			}

			.red {
				background-color: #f44336 !important;
			}
		}
		.whm-day-name {
			height: auto;
			background: transparent;
			overflow-x: hidden;
		}
	}
}
/*
.infotip {
	display: none;
}
*/
/*
.has_infotip {
	position: relative;

	.infotip {
		position: absolute;
		left: 50%;
		transform: translate(-50%, -10px);
		bottom: 100%;
		background-color: black;
		color: white;
		display: none;
		padding: 10px;
		max-width: 200px;
		font-size: 14px;

		line-height: 1.4em;

		&:after {
			content: "";
			position: absolute;
			display: block;
			left: 50%;
			margin-left: -10px;
			top: 100%;
			width: 0;
			height: 0;
			border: 10px solid transparent;
			border-top: 10px solid black;
		}
	}
}

.has_infotip:hover {
	z-index: 99999;
	.infotip {
		display: block;
		text-align: left;
	}
}
*/
</style>
