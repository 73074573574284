// Welcome.vue

<template>
	<div class style>
		<div class="whm-col" :style="'width: ' + 100 / 8 + '%'">
			<div class="whm-row">&nbsp;-</div>
			<div class="whm-row" v-for="h in 24" :key="h + '-whmap-twenty-four'">
				<div class="whm-hour">{{ hours[h - 1] }}</div>
			</div>
		</div>

		<div
			class="whm-col"
			v-for="(day, i) in callData.hourly_volume"
			:key="i + '-whmap-call-data-hourly-volume'"
			:style="'width: ' + 100 / 8 + '%'"
		>
			<div class="whm-row whm-day-name">{{ week[i] }}</div>
			<div
				class="whm-row"
				v-for="(h, j) in day.hours"
				:key="j + '-whmap-day-hours'"
			>
				<section
					class="whm-color"
					:title="h.calls_count"
					:class="colorClass"
					:style="'opacity: ' + percentage(h.calls_count_avg)"
				>
					{{ h.calls_count_avg }}&nbsp;
				</section>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["callData", "colorClass"],
	name: "WeeklyHeatMap.vue",
	data() {
		return {
			msg: "Welcome to Your Vue.js App",

			week: ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"],
			hours: [
				"12:00 am",
				"1:00 am",
				"2:00 am",
				"3:00 am",
				"4:00 am",
				"5:00 am",
				"6:00 am",
				"7:00 am",
				"8:00 am",
				"9:00 am",
				"10:00 am",
				"11:00 am",

				"12:00 pm",
				"1:00 pm",
				"2:00 pm",
				"3:00 pm",
				"4:00 pm",
				"5:00 pm",
				"6:00 pm",
				"7:00 pm",
				"8:00 pm",
				"9:00 pm",
				"10:00 pm",
				"11:00 pm"
			]
		};
	},

	methods: {
		round(value, precision) {
			var multiplier = Math.pow(10, precision || 0);
			return Math.round(value * multiplier) / multiplier;
		},

		percentage(count) {
			if (this.callData.max_hourly_count == 0) {
				return 0;
			}

			//return (count/12);
			return this.round(count / this.callData.max_hourly_count, 1);
		}
	},

	mounted() {}
};
</script>

<style lang="scss">
.whm-col {
	float: left;
	border-right: 1px solid #dee2e6;
	text-align: center;
	//padding: 5px;

	.whm-row {
		border-bottom: 1px solid #dee2e6;
		padding: 0.1em;
		white-space: nowrap;
		overflow: hidden;
		font-size: 12px;

		.whm-color {
			color: white;
			//margin: -.15em;
		}

		a:nth-child(5) {
			outline: 3px solid lightgreen;
			background-color: green !important;
			position: relative;
			left: 50%;
		}
	}

	.whm-hour {
		text-align: right;
		margin-right: 0.2em;
	}

	.whm-day-name {
		color: gray;
	}
}
</style>
