// Welcome.vue

<template>
	<div class="" style="margin-top: -20px; padding-bottom: 20px;">
		<canvas :id="canvasId" width="400" height="200"></canvas>
	</div>
</template>

<script>
export default {
	props: ["canvasId", "totalCalls", "missedCalls"],
	name: "PieChart.vue",
	data() {
		return {
			msg: "Welcome to Your Vue.js App"
		};
	},

	watch: {
		totalCalls() {
			this.initPieChart();
		},
		missedCalls() {
			this.initPieChart();
		}
	}, //watch

	methods: {
		initPieChart() {
			//myChart
			let ctx = document.getElementById(this.canvasId);

			//WidgetChart 4
			//var ctx = document.getElementById( "widgetChart4" );
			//ctx.height = 70;
			// eslint-disable-next-line no-undef,no-unused-vars
			var myChart = new Chart(ctx, {
				type: "doughnut",
				data: {
					labels: ["Missed calls", "Answered Calls"],
					datasets: [
						{
							label: "% of missed calls",
							data: [this.missedCalls, this.totalCalls],
							//borderColor: "rgba(0, 123, 255, 0)",
							//borderWidth: "0",
							backgroundColor: ["#ff8a80", "#ff5252"],
							borderColor: "#E53935",
							borderWidth: 4
						}
					]
				},
				options: {
					borderColor: "#ff0000",
					maintainAspectRatio: true,
					legend: {
						display: false
					},
					scales: {
						xAxes: [
							{
								display: false,
								categoryPercentage: 1,
								barPercentage: 0.5
							}
						],
						yAxes: [
							{
								display: false
							}
						]
					}
				}
			});
		}
	}, // methods

	mounted() {
		//this.initPieChart();
	}
};
</script>

<style lang="scss"></style>
