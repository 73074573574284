<template>
	<div class="" style="">
		<table class="table table-bordered table-sm">
			<tr>
				<th width="30%">Facility</th>

				<th>Usage</th>
			</tr>
			<tr
				v-for="facility in facility_usage_reports.facilities"
				:key="facility.id + '-facility-usage-reports'"
			>
				<td>{{ facility.name }}</td>
				<td>
					<div>
						{{ facility.users_count }} of
						<span v-if="facility.user_limit < 0">Unlimited</span>
						<span v-else>{{ facility.user_limit }}</span> Users
					</div>

					<div
						v-if="facility.user_limit > 0"
						class="progress light-green lighten-1 mt-0 mb-2"
						style="height:10px"
					>
						<div
							class="progress-bar light-green darken-2"
							style="width:40%;height:10px"
							:style="
								'width: ' +
									returnPercentage(facility.users_count, facility.user_limit)
							"
						></div>
					</div>

					<span v-if="facility.rtnow_feature.active">
						{{ facility.rtnow_feature.total_minutes_used }} of
						{{
							facility.rtnow_feature.contracted_minutes > 0
								? facility.rtnow_feature.contracted_minutes
								: "Unlimited"
						}}
						&nbsp;rtNOW minutes

						<div
							v-if="facility.rtnow_feature.contracted_minutes > 0"
							class="progress light-green lighten-1 mt-0 mb-2"
							style="height:10px"
						>
							<div
								class="progress-bar light-green darken-2"
								style="height:10px"
								:style="
									'width: ' +
										returnPercentage(
											facility.rtnow_feature.total_minutes_used,
											facility.rtnow_feature.contracted_minutes
										)
								"
							></div>
						</div>
					</span>

					<div v-if="facility.corelink_feature.active">
						{{ facility.corelink_feature.total_minutes_used }} of
						{{
							facility.corelink_feature.contracted_minutes > 0
								? facility.corelink_feature.contracted_minutes
								: "Unlimited "
						}}
						CoreLink minutes

						<div
							v-if="facility.corelink_feature.contracted_minutes > 0"
							class="progress light-green lighten-1 mt-0 mb-2"
							style="height:10px;"
						>
							<div
								class="progress-bar light-green darken-2"
								style="height:10px"
								:style="
									'width: ' +
										returnPercentage(
											facility.corelink_feature.total_minutes_used,
											facility.corelink_feature.contracted_minutes
										)
								"
							></div>
						</div>
					</div>
				</td>
				<!--				<td>-->
				<!--				{{-->
				<!--					facility.users_count-->
				<!--				}}-->
				<!--				of-->
				<!--				<span v-if="facility.user_limit < 0">Unlimited</span>-->
				<!--				<span v-else>{{ facility.user_limit }}</span>-->
				<!--				</td>-->
				<!--				<td style="text-align: center" class="blue-grey-text">-->

				<!--				</td>-->
				<!--				<td style="text-align: center" class="blue-grey-text">-->
				<!--					<span v-if="facility.corelink_feature.active">-->
				<!--						{{ facility.corelink_feature.total_minutes_used }} of-->
				<!--						{{ facility.corelink_feature.contracted_minutes }} minutes-->
				<!--					</span>-->
				<!--					<i v-else class=" material-icons blue-grey-text mt-1">cancel</i>-->
				<!--				</td>-->
			</tr>
		</table>
	</div>
</template>

<script>
export default {
	props: ["facility_usage_reports"],
	name: "FacilityUsageReports.vue",
	methods: {
		returnPercentage(enumerator, denominator) {
			return (enumerator / denominator) * 100 + "%";
		}
	}
};
</script>

<style scoped></style>
