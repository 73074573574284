// Welcome.vue

<template>
	<div class="" style="">
		<table class="table table-bordered table-sm">
			<tr>
				<th>Date</th>
				<th>Start</th>
				<th>Location</th>
				<th>RT</th>
				<th>Purpose</th>
				<th>Duration</th>
			</tr>
			<tr v-for="(n, i) in computedCalls" :key="i + '-computed-calls'">
				<td>{{ n.start_date }}</td>
				<td>{{ n.start_time }}</td>
				<td v-if="n.facility_name">{{ n.facility_name }}</td>
				<td v-else>rtNOW</td>
				<td>{{ n.first_name }} {{ n.last_name }}</td>
				<td>
					<div
						v-for="(p, ii) in n.purpose"
						:key="ii + '-computed-calls-purpose'"
					>
						{{ p }}
					</div>
				</td>
				<td>
					<span class="blue-grey-text">{{ n.duration }}</span>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import moment from "moment";

export default {
	props: ["callData"],
	name: "CallLogTable.vue",

	data() {
		return {
			msg: "Welcome to Your Vue.js App"
		};
	},

	computed: {
		computedCalls() {
			var calls = [];
			//return this.callData.call_volume;

			this.callData.call_volume.forEach(function(element) {
				element.start_date = moment(element.start).format("MMM D, Y");
				element.start_time = moment(element.start).format("h:mm A");

				//var x = moment(element.start);
				//var y = moment(element.end);
				//element.duration = moment(x.diff(y, 'seconds', true)).format('h:mm:ss');
				//element.duration = moment.duration(y.diff(x));

				//element.duration = moment.duration(y.diff(x)).humanize()

				calls.push(element);
			});

			return calls;
		}
	},

	mounted() {}
};
</script>

<style lang="scss"></style>
